import { FC, memo, useCallback, useContext } from "react";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import { BetSlipContext } from "@finbackoffice/site-core";
import { MarketOutcomeStatus, EventStatus } from "@finbackoffice/enums";
import { Svg } from "components/base/svg/Svg";
import { MarketUpdatesContext } from "contexts";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { useMainMarketOdds } from "hooks";
import Market from "components/pages/sport/base/market/Market";
import HomeTournamentGameScoreboard from "./scoreboard/HomeTournamentGameScoreboard";
import styles from "./home-tournament-game-item.module.sass";

type Props = {
    game: IEventFeedResponse;
};

const HomeTournamentGameItem: FC<Props> = ({ game }) => {
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const gameId = game?.id;
    const homeName = game?.home_team?.name || "";
    const awayName = game?.away_team?.name || "";
    const sportId = game?.sport_id;
    const { mainMarket, mainMarketName, mainMarketStatus, mainMarketTemplateId } =
        useMainMarketOdds(sportId, game.markets);

    const onOutcomeClick = useCallback(
        (marketId: number, outcome: IMarketOutcomeFeedResponse) => {
            if (mainMarketStatus) {
                if (!isOutcomeSelected(outcome.id)) {
                    addBetItem({
                        gameId,
                        marketGroupId: mainMarketTemplateId?.toString() || "",
                        marketId,
                        outcomeId: outcome.id,
                        team1Name: homeName,
                        team2Name: awayName,
                        marketName: mainMarketName,
                        marketStatus: mainMarketStatus,
                        outcomeName: outcome.name || "",
                        outcomeValue: outcome.odds,
                        outcomeStatus: MarketOutcomeStatus.Active,
                        outcomeInitValue: outcome.odds,
                        stake: "",
                        loading: false,
                        accepted: false,
                    });
                    subscribeMarket(gameId, marketId, outcome.id);
                } else {
                    removeBetItem(outcome.id);
                    unsubscribeMarket(outcome.id);
                }
            }
        },
        [
            addBetItem,
            awayName,
            gameId,
            homeName,
            mainMarketName,
            mainMarketStatus,
            mainMarketTemplateId,
            subscribeMarket,
            unsubscribeMarket,
            removeBetItem,
            isOutcomeSelected,
        ],
    );

    return (
        <FadeInAnimation>
            <div className={styles.homeGameItem}>
                <div className={styles.homeGameItemHeader}>
                    <div>
                        <Svg
                            src={`/common/regions/${game.category_id}.svg`}
                            wrapper="span"
                            className="svg-region-icon"
                            loader
                        />
                        {`${game.category_name} / ${game.tournament_name}`}
                    </div>
                    {game.status === EventStatus.Live && (
                        <span className={styles.liveGameLabel}>Live</span>
                    )}
                </div>
                <HomeTournamentGameScoreboard game={game} />
                <div className={styles.homeGameItemMarket}>
                    {mainMarket && (
                        <Market
                            market={mainMarket}
                            key={mainMarket.id}
                            onOutcomeClick={onOutcomeClick}
                        />
                    )}
                </div>
            </div>
        </FadeInAnimation>
    );
};

export default memo(HomeTournamentGameItem);
