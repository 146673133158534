import { FC, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import Link from "next/link";
import { useTranslation, useRuntimeConfig } from "@finbackoffice/site-core";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import { CasinoGameItem } from "components/pages/casino/game-item/CasinoGameItem";
import { CasinoGamesContext } from "components/pages/casino/games/CasinoGames";
import CasinoWidgetContainer from "../../casino-widget-container/CasinoWidgetContainer";
import styles from "./casino-games-row.module.sass";

export enum CasinoGamesRowVariantEnum {
    Horizontal = "horizontal",
}

type IProps = {
    variant: CasinoGamesRowVariantEnum;
    tag?: CasinoGameTag;
    headerIconFilename?: string;
};

const CasinoGamesRow: FC<IProps> = ({ tag, headerIconFilename, variant }) => {
    const { id, casinoGamesState, href, label, isSlots, imgSrc } = useContext(CasinoGamesContext);
    const { t: tWidgets } = useTranslation(TranslationScopes.CasinoWidgets);
    const { t } = useTranslation(TranslationScopes.Casino);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");

    const sliderSettings: Settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            variableWidth: true,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1762,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1532,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        }),
        [],
    );

    const renderHeaderButton = useMemo(
        () =>
            href && (
                <Link href={href}>
                    <Translate tid="home_show_all" namespace={TranslationScopes.Home} />
                </Link>
            ),
        [href],
    );

    const renderHeader = useMemo(() => {
        const totalCount = casinoGamesState.totalCount;
        const titleKey = `casino_${id}_title`;
        const subTitleKey = `casino_${id}_sub_title`;
        const titleTranslationById = tWidgets(titleKey);
        const subTitleTranslationById = tWidgets(subTitleKey);
        const isSubTitleFallback =
            subTitleTranslationById === "" || subTitleTranslationById === subTitleKey;
        const isTitleFallback = titleTranslationById === titleKey;

        const showTitle =
            ((!isTitleFallback || !isSubTitleFallback) && id) ||
            ((isTitleFallback || isSubTitleFallback) && label);

        return (
            <>
                <h2 className={styles.headerSport}>
                    <Svg
                        src={`/common/desktop/base-icons/${headerIconFilename}.svg`}
                        wrapper="span"
                        className="svg-icon"
                    />
                    {showTitle && id && !isTitleFallback
                        ? !!titleTranslationById.trim() && (
                              <Translate
                                  tid={`casino_${id}_title`}
                                  namespace={TranslationScopes.CasinoWidgets}
                              />
                          )
                        : label &&
                          typeof label !== "string" && (
                              <Translate
                                  tid={`${label?.title}`}
                                  namespace={TranslationScopes.Home}
                              />
                          )}
                </h2>
                <h2>
                    {id && !isSubTitleFallback ? (
                        <Translate
                            tid={`casino_${id}_sub_title`}
                            namespace={TranslationScopes.CasinoWidgets}
                            replace={{
                                app_name: COMMON_SITE_CONFIGS.appName,
                                tag: tag ? t(`casino_${tag?.toLowerCase()}`) : "",
                            }}
                        />
                    ) : (
                        label &&
                        typeof label !== "string" && (
                            <Translate
                                tid={`${label?.sub_title}`}
                                namespace={TranslationScopes.Home}
                                replace={{
                                    app_name: COMMON_SITE_CONFIGS.appName,
                                    tag: tag ? t(`casino_${tag?.toLowerCase()}`) : "",
                                }}
                            />
                        )
                    )}
                </h2>
                {!!totalCount && renderHeaderButton}
            </>
        );
    }, [
        COMMON_SITE_CONFIGS.appName,
        casinoGamesState.totalCount,
        headerIconFilename,
        id,
        label,
        renderHeaderButton,
        t,
        tWidgets,
        tag,
    ]);

    const widgetVariant = useMemo(() => {
        switch (variant) {
            case CasinoGamesRowVariantEnum.Horizontal:
                return "rowHorizontal";
            default:
                return undefined;
        }
    }, [variant]);

    return (
        <CasinoWidgetContainer
            variant={widgetVariant}
            renderHeader={renderHeader}
            loading={casinoGamesState.loading}>
            {casinoGamesState.games.length !== 0 && (
                <FadeInAnimation>
                    <div className={styles.casinoRowGames}>
                        <div>
                            <SlickSlider settings={sliderSettings}>
                                {casinoGamesState.games.map((game) => (
                                    <CasinoGameItem
                                        key={game.id}
                                        game={game}
                                        hidePlayButtons={!isSlots}
                                        imgSrc={imgSrc}
                                    />
                                ))}
                            </SlickSlider>
                        </div>
                    </div>
                </FadeInAnimation>
            )}
        </CasinoWidgetContainer>
    );
};

export default CasinoGamesRow;
