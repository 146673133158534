import { ICasinoFavoriteResponse, ICasinoGame } from "@finbackoffice/clientbff-client";
import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import {
    sortByCasinoTagsOrder,
    CasinoGameTypeEnum,
    CasinoLayoutEnum,
    ISiteConfigLayouts,
    CasinoWidgetImgSrcEnum,
} from "@finbackoffice/fe-core";
import dynamic from "next/dynamic";
import {
    AuthContext,
    CasinoFavoritesContext,
    CasinoScopesMap,
    ModalsContext,
    useSiteConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { FC, createContext, useCallback, useContext, useMemo, useState } from "react";
import { RouteNames } from "utils/constants";
import { useCasinoMethods } from "hooks";

const Compact = dynamic(() => import("./layouts/compact/Compact"), {
    ssr: false,
});

const GameStore = dynamic(() => import("./layouts/game-store/GameStore"), {
    ssr: false,
});

export const CasinoGameTagFlags = [
    CasinoGameTag.New,
    CasinoGameTag.Top,
    CasinoGameTag.Hot,
    CasinoGameTag.Popular,
];

export const tagIconClassesMap: Record<string, string> = {
    [CasinoGameTag.New]: "icon-casino-new",
    [CasinoGameTag.Top]: "icon-casino-top",
    [CasinoGameTag.Hot]: "icon-casino-hot",
    [CasinoGameTag.Popular]: "icon-casino-popular",
};

type IProps = {
    game: ICasinoGame | ICasinoFavoriteResponse;
    disableLocalThumbnail?: boolean;
    showName?: boolean;
    hideTag?: CasinoGameTag[];
    fallbackSrc?: string;
    useMegawaysImgSrc?: boolean;
    hidePlayButtons?: boolean;
    imgSrc?: CasinoWidgetImgSrcEnum;
};

type ICasinoGameItemContext = IProps & {
    gameId: string;
    handlePlayNow: () => void;
    handlePlayForFun: () => void;
    gameName: string;
    isUserLoggedIn: boolean;
    handleFavoriteClick: () => void;
    isFavorite: boolean;
    isSlots: boolean;
    tagsMemo: CasinoGameTag[];
    isFallbackSrcActive: boolean;
    onFallbackHandler: () => void;
};

export const CasinoGameItemContext = createContext<ICasinoGameItemContext>(null as any);

export const CasinoGameItem: FC<IProps> = ({
    game,
    disableLocalThumbnail,
    showName = false,
    hideTag,
    fallbackSrc,
    useMegawaysImgSrc,
    hidePlayButtons,
    imgSrc,
}) => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const router = useRouter();
    const isSlots = CasinoScopesMap.slots.includes(game.type as CasinoGameTypeEnum);
    const { t } = useTranslation(`${TranslationScopes.Casino}_${isSlots ? "slots" : "livecasino"}`);
    const { createCasinoFavorite, deleteCasinoFavorite, favoriteGamesState } =
        useContext(CasinoFavoritesContext);
    const { loginModalRef } = useContext(ModalsContext);
    const { handlePlayForFun, handlePlayNow } = useCasinoMethods(loginModalRef);
    const { isUserLoggedIn } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [isFallbackSrcActive, setIsFallbackSrcActive] = useState(false);
    const gameId = useMemo(
        () => (typeof game.id === "string" ? game.id : (game as ICasinoFavoriteResponse).game_id),
        [game],
    );
    const gameNameTid = useMemo(() => gameId.replaceAll("-", "_"), [gameId]);
    const gameName = useMemo(
        () => (isSlots ? game.name : t(gameNameTid) !== gameNameTid ? t(gameNameTid) : game.name),
        [game.name, gameNameTid, isSlots, t],
    );
    const isFavorite = useMemo(
        () => !!favoriteGamesState.games.find((favGame) => favGame.game_id === gameId) ?? false,
        [favoriteGamesState.games, gameId],
    );
    const tagsMemo = useMemo(() => {
        return CasinoGameTagFlags.filter(
            (tag) => game.tags.includes(tag) && !hideTag?.includes(tag),
        ).sort(sortByCasinoTagsOrder);
    }, [game.tags, hideTag]);

    const handleFavoriteClick = useCallback(async () => {
        if (!loading) {
            setLoading(true);
            if (isFavorite) {
                await deleteCasinoFavorite(gameId);
            } else {
                await createCasinoFavorite(gameId);
            }
            setLoading(false);
        }
    }, [createCasinoFavorite, deleteCasinoFavorite, gameId, isFavorite, loading]);

    const onFallbackHandler = useCallback(() => {
        setIsFallbackSrcActive(true);
    }, []);

    const handlePlayNowMemo = useCallback(
        () => handlePlayNow(gameId, isSlots),
        [gameId, handlePlayNow, isSlots],
    );

    const handlePlayForFunMemo = useCallback(
        () => handlePlayForFun(gameId, isSlots),
        [gameId, handlePlayForFun, isSlots],
    );

    const renderLayout = useMemo(() => {
        switch (siteLayoutsConfig._casino.type) {
            case CasinoLayoutEnum.GameStore:
                if (router.pathname.includes(`${RouteNames.CASINO}/`)) {
                    return <GameStore />;
                }
                return <Compact />;
            default:
                return <Compact />;
        }
    }, [router.pathname, siteLayoutsConfig._casino.type]);

    const value = useMemo(
        () => ({
            gameId,
            game,
            disableLocalThumbnail,
            handlePlayNow: handlePlayNowMemo,
            handlePlayForFun: handlePlayForFunMemo,
            gameName,
            isUserLoggedIn,
            isFavorite,
            handleFavoriteClick,
            showName,
            fallbackSrc,
            useMegawaysImgSrc,
            hidePlayButtons,
            isSlots,
            tagsMemo,
            isFallbackSrcActive,
            setIsFallbackSrcActive,
            onFallbackHandler,
            imgSrc,
        }),
        [
            game,
            gameId,
            disableLocalThumbnail,
            gameName,
            handleFavoriteClick,
            handlePlayForFunMemo,
            handlePlayNowMemo,
            isFavorite,
            isUserLoggedIn,
            showName,
            fallbackSrc,
            useMegawaysImgSrc,
            hidePlayButtons,
            isSlots,
            tagsMemo,
            isFallbackSrcActive,
            setIsFallbackSrcActive,
            onFallbackHandler,
            imgSrc,
        ],
    );

    return (
        <CasinoGameItemContext.Provider value={value}>
            {renderLayout}
        </CasinoGameItemContext.Provider>
    );
};
