import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import Link from "next/link";
import { FC, useMemo } from "react";
import { SportTypes } from "@finbackoffice/fe-core";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import { RouteNames } from "utils/constants";
import HomeTournamentGameScoreboardDefault from "./default/HomeTournamentGameScoreboardDefault";
import HomeTournamentGameScoreboardSoccer from "./soccer/HomeTournamentGameScoreboardSoccer";
import styles from "./home-tournament-game-scoreboard.module.sass";

type Props = {
    game: IEventFeedResponse;
};

const HomeTournamentGameScoreboard: FC<Props> = ({ game }) => {
    const scoreboard = useMemo(() => {
        switch (game.sport_id) {
            case SportTypes.SOCCER:
                return <HomeTournamentGameScoreboardSoccer game={game} />;
            default:
                return <HomeTournamentGameScoreboardDefault game={game} />;
        }
    }, [game]);

    return (
        <ErrorBoundary name={HomeTournamentGameScoreboard.name}>
            <Link
                href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${game.sport_slug}/${game.category_id}/${game.tournament_id}/${game.id}`}
                className={styles.scoreboardLink}>
                {scoreboard}
            </Link>
        </ErrorBoundary>
    );
};

export default HomeTournamentGameScoreboard;
