import { FC, useMemo } from "react";
import { IMarketFeedResponse, IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import Outcome from "../outcome/Outcome";
import styles from "./market.module.sass";

interface IMarketProps {
    market: IMarketFeedResponse;
    onOutcomeClick: (marketId: number, outcome: IMarketOutcomeFeedResponse) => void;
}

const Market: FC<IMarketProps> = ({ market, onOutcomeClick }) => {
    const columnCount: number = market.outcomes_count > 2 ? 3 : 2;

    const getDummyOdds = (outcomesCount: number, column: number) => {
        const odds: (IMarketOutcomeFeedResponse | null)[] = [];
        let count = 0;

        if (outcomesCount < column) {
            count = column - outcomesCount;
        } else if (outcomesCount % column > 0 && outcomesCount > column) {
            count = column - (outcomesCount % column);
        }

        if (count !== 0) {
            for (let i = 0; i < count; ++i) {
                odds.push(null);
            }
        }

        return odds;
    };

    const dummyOdds: (IMarketOutcomeFeedResponse | null)[] = useMemo(
        () => getDummyOdds(market.outcomes.length, columnCount),
        [market.outcomes.length, columnCount],
    );

    return (
        <div className={styles.market}>
            {market.outcomes.map((outcome) => (
                <Outcome
                    key={outcome.id}
                    outcome={outcome}
                    marketStatus={market.status}
                    onOutcomeClick={(outcome) => onOutcomeClick(market.id, outcome)}
                />
            ))}
            {dummyOdds.map((outcome, index) => (
                <Outcome key={index} outcome={outcome} />
            ))}
        </div>
    );
};

export default Market;
