import { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import classnames from "classnames";
import Link from "next/link";
import Loading from "components/base/loading/Loading";
import { Svg } from "components/base/svg/Svg";
import styles from "./casino-widget-container.module.sass";

type CasinoWidgetContainerDefaultHeaderProps = {
    title: string;
    iconSrc?: string;
    link?: { path: string; name: string };
};

type CasinoWidgetContainerVariant = "carousel" | "rowHorizontal" | "default";

type Props = {
    renderHeader?: ReactNode;
    defaultHeaderProps?: CasinoWidgetContainerDefaultHeaderProps;
    variant?: CasinoWidgetContainerVariant;
    loading?: boolean;
    wrapperClassname?: string;
};

const CasinoWidgetContainer: FC<Props & PropsWithChildren> = ({
    children,
    loading,
    wrapperClassname,
    variant = "default",
    renderHeader,
    defaultHeaderProps,
}) => {
    const renderDefaultHeader = useMemo(() => {
        const { iconSrc, title, link } = defaultHeaderProps || {};
        return (
            <>
                <div className={styles.tagName}>
                    {iconSrc && <Svg src={iconSrc} wrapper="span" className="svg-icon" />}
                    {title}
                </div>
                {link && (
                    <Link href={link.path} className={styles.showAll}>
                        {link.name}
                    </Link>
                )}
            </>
        );
    }, [defaultHeaderProps]);

    return (
        <div
            className={classnames(
                variant && styles[variant],
                wrapperClassname && wrapperClassname,
            )}>
            <div className={styles.header}>{renderHeader || renderDefaultHeader}</div>
            {children}
            {loading && <Loading />}
        </div>
    );
};

export default CasinoWidgetContainer;
