import { FC } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { ITranslateProps } from "@finbackoffice/site-core";
import Translate from "../translate/Translate";
import styles from "./faq.module.sass";

type IProps = {
    namespace: TranslationScopes;
    ids: {
        id: string;
        question?: Partial<ITranslateProps>;
        answer?: Partial<ITranslateProps>;
    }[];
    head?: Partial<ITranslateProps>;
    hideHead?: boolean;
};

const Faq: FC<IProps> = ({ namespace, ids, hideHead, head }) => {
    return (
        <div itemScope itemType="https://schema.org/FAQPage" className={styles.faq}>
            {!hideHead && (
                <h1 className={styles.faqHeadText}>
                    <Translate tid="faq_head" namespace={namespace} replace={head?.replace} />
                </h1>
            )}

            {ids.map((item) => (
                <section key={item.id} className="ms_faq ms_card">
                    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className="accordionButton">
                            {item.question?.dangerous ? (
                                <Translate
                                    tid={`faq_${item.id}_question`}
                                    dangerous
                                    replace={item.question.replace}
                                    namespace={namespace}>
                                    {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                    <h2 itemProp="name" />
                                </Translate>
                            ) : (
                                <h2 itemProp="name">
                                    <Translate
                                        tid={`faq_${item.id}_question`}
                                        replace={item.question?.replace}
                                        namespace={namespace}
                                    />
                                </h2>
                            )}
                        </div>
                        <div
                            itemScope
                            itemProp="acceptedAnswer"
                            itemType="https://schema.org/Answer">
                            {item.answer?.dangerous ? (
                                <Translate
                                    tid={`faq_${item.id}_answer`}
                                    dangerous
                                    namespace={namespace}
                                    replace={item.answer.replace}>
                                    <div itemProp="text" />
                                </Translate>
                            ) : (
                                <p itemProp="text">
                                    <Translate
                                        tid={`faq_${item.id}_answer`}
                                        namespace={namespace}
                                        replace={item.answer?.replace}
                                    />
                                </p>
                            )}
                        </div>
                    </div>
                </section>
            ))}
        </div>
    );
};

export default Faq;
