import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import classnames from "classnames";
import React, { FC } from "react";
import { EventStatus } from "@finbackoffice/enums";
import { useDate, DEFAULT_DATE_FORMAT } from "hooks";
import TeamIcon from "components/pages/sport/base/team-icon/TeamIcon";
import styles from "../home-tournament-game-scoreboard.module.sass";

type Props = {
    game: IEventFeedResponse;
};

const HomeTournamentGameScoreboardDefault: FC<Props> = ({ game }) => {
    const { formatDate } = useDate();

    return (
        <div className={styles.game}>
            {game.status === EventStatus.Live ? (
                <>
                    <div className={styles.gameHeader}>
                        <div>{game.match_status}</div>
                        <span>1</span>
                        <span className={styles.check}>2</span>
                        <span>T</span>
                    </div>
                    <div className={styles.team}>
                        <TeamIcon sportId={game.sport_id} teamId={game.home_team?.id} />
                        <i className={classnames(styles.inning, styles.active)} />
                        <span className={styles.teamName}>{game.home_team?.name}</span>
                        <span className={classnames(styles.score, styles.check)}>26</span>
                        <span className={styles.score}>10</span>
                        <span className={styles.total}>
                            <span>{game.scoreboard?.home_score}</span>
                        </span>
                    </div>
                    <div className={styles.team}>
                        <TeamIcon sportId={game.sport_id} teamId={game.away_team?.id} />
                        <i className={styles.inning} />
                        <span className={styles.teamName}>{game.away_team?.name}</span>
                        <span className={styles.score}>10</span>
                        <span className={styles.score}>16</span>
                        <span className={styles.total}>
                            <span>{game.scoreboard?.away_score}</span>
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.gameHeader}>
                        {game.scheduled_at &&
                            formatDate(new Date(game.scheduled_at), `${DEFAULT_DATE_FORMAT} HH:mm`)}
                    </div>

                    <div className={styles.team}>
                        <TeamIcon sportId={game.sport_id} teamId={game.home_team?.id} />
                        <span className={styles.teamName}>{game.home_team?.name}</span>
                    </div>
                    <div className={styles.team}>
                        <TeamIcon sportId={game.sport_id} teamId={game.away_team?.id} />
                        <span className={styles.teamName}>{game.away_team?.name}</span>
                    </div>
                </>
            )}
        </div>
    );
};

export default HomeTournamentGameScoreboardDefault;
