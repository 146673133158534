import { GetServerSidePropsContext, Page } from "next";
import React from "react";
import { SiteContentScopes } from "@finbackoffice/enums";
import { TranslationScopes } from "@finbackoffice/enums";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Seo from "components/base/seo/Seo";
import Home from "components/pages/home/Home";
import { getServerData } from "services/ssr/getServerData";
import { getSportsbookLayout } from "components/layout/sportsbook/SportsbookLayout";
import {
    clientBFFSingleton,
    withSsrBanners,
    withSsrCasinoFavorites,
    createGetServerSideProps,
    CommonSsrProps,
    getServerRuntimeConfig,
} from "services/ssr";
import { getCurrentLocale } from "utils/helpers";

export const getServerSideProps = createGetServerSideProps<CommonSsrProps>(
    async (context: GetServerSidePropsContext) => {
        const runtimeConfig = await getServerRuntimeConfig();
        const client = clientBFFSingleton.getInstance(context.req.cookies.jwt);
        const locale = getCurrentLocale(runtimeConfig, context.locale);

        const bannerScope = [SiteContentScopes.Homepage];

        const translationScope = [
            TranslationScopes.Home,
            TranslationScopes.Casino,
            TranslationScopes.CasinoSlots,
            TranslationScopes.CasinoLiveCasino,
            TranslationScopes.CasinoWidgets,
        ];

        const [{ translations, configs, profile }, banners, casinoFavoriteGames] =
            await Promise.all([
                getServerData(runtimeConfig, translationScope, context, client),
                withSsrBanners(runtimeConfig, client, locale, bannerScope),
                context.req.cookies.jwt ? withSsrCasinoFavorites(client) : undefined,
            ]);

        const props = {
            configs,
            runtimeConfig,
            jwt: context.req.cookies.jwt || "",
            profile,
            locale,
            translations,
            banners: banners.items,
            casinoFavoriteGames: casinoFavoriteGames?.items || null,
        };

        return {
            props,
        };
    },
);

const IndexPage: Page = () => {
    return (
        <ErrorBoundary name={Home.name}>
            <Seo page={TranslationScopes.Home} />
            <Home />
        </ErrorBoundary>
    );
};

IndexPage.getLayout = getSportsbookLayout;

export default IndexPage;
